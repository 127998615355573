.mr-0 {
	margin-right: 0 !important;
}

.mr-1 {
	margin-right: 1rem;
}

.mr-2 {
	margin-right: 2rem;
}

.ml-0 {
	margin-left: 0 !important;
}

.ml-1 {
	margin-left: 1rem;
}

.ml-2 {
	margin-left: 2rem;
}

.mt-0 {
	margin-top: 0rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mt-5 {
	margin-top: 5rem;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 2rem;
}

.mx-1 {
	margin-right: 1rem;
	margin-left: 1rem;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.pl-0 {
	padding-left: 0 !important;
}

.pl-1 {
	padding-left: 1rem !important;
}

.pl-2 {
	padding-left: 2rem !important;
}

.p-1 {
	padding: 1rem !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pr-1 {
	padding-right: 1rem !important;
}

.pr-2 {
	padding-right: 2rem !important;
}

.pb-1 {
	padding-bottom: 1rem !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.w-100 {
	width: 100%;
}

.vh-70 {
	height: 70vh !important;
}

.maw-50 {
	max-width: 50px !important;
}

.maw-70 {
	max-width: 70px !important;
}

.maw-100 {
	max-width: 100px !important;
}

.maw-150 {
	max-width: 150px !important;
}

.container {
	min-width: 950px;
	padding-left: 32px !important;
	padding-right: 32px !important;
}

.noUpper {
	text-transform: capitalize !important;
}

.sb {
	margin-left: -5px;
	width: 100%;
	text-overflow: ellipsis;
}
.edit-dis input[type='text']:not(.browser-default):disabled {
	color: black;
	border-bottom: none;
	margin-bottom: 0;
}

.stat-dis input[type='text']:not(.browser-default):disabled {
	color: black;
	border-bottom: 1px solid black;
}

/*input.select-dropdown {*/
/*color: #ffffff;*/
/*}*/

.select-wrapper input.select-dropdown,
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default) {
	height: 2.5rem;
	line-height: 2.5rem;
}

textarea.materialize-textarea {
	min-height: 2.5rem;
}

.clear-row {
	display: flex;
	/*flex-direction: column;*/
	/*justify-content: space-between;*/
	/*align-items: stretch;*/
	/*flex-wrap: wrap;*/
	/*align-items: center;*/
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.justify-around {
	justify-content: space-around;
}

.justify-between {
	justify-content: space-between;
}

.justify-start {
	justify-content: start;
}

.justify-end {
	justify-content: end;
}

.align-stretch {
	align-items: stretch;
}

.align-center {
	align-items: center;
}

.align-bottom {
	align-items: flex-end;
}

.align-top {
	align-items: flex-start;
}

.td-top {
	vertical-align: top;
}

.td-mid {
	vertical-align: middle;
}

/*@media (max-width: 1100px) {*/
/*.clear-row {*/
/*flex-direction: column;*/
/*}*/
/*}*/

.clear-row::after {
	content: none;
	display: none;
}

.noColorNav {
	background-color: transparent;
	box-shadow: none;
}

.col-auto {
	display: block;
}

.overflowHidden {
	overflow: hidden !important;
}

nav ul a {
	border-radius: 5px;
	outline: none;
}

nav ul a:hover {
	background-color: #f0f3f9 !important;
	border-radius: 5px;
}

ul a {
	outline: none;
	color: black;
}

ul li {
	padding: 2px 5px;
}

ul li:hover,
.active-menu {
	background-color: #f0f3f9 !important;
	cursor: pointer;
	border-radius: 5px;
}

.text-gray * {
	color: rgba(0, 0, 0, 0.6) !important;
}

.txt-gray {
	color: rgba(0, 0, 0, 0.6) !important;
}

.dropdown-content {
	min-width: 300px;
	border-radius: 5px;
	/*background-color: #484747;*/
}

.dropdown-content a {
	border-radius: 0;
}

.dropdown-content a:hover {
	border-radius: 0;
}

.carousel .carousel-item > img {
	width: 240%;
	margin-left: -65%;
	margin-top: -40%;
	max-height: 315px;
}

.carousel {
	height: 500px;
	/*border: 2px solid red;*/
}

.carousel-slider .carousel-item > img {
	width: 100%;
	margin-left: 0;
	margin-top: 0;
	max-height: 100%;
}

.carousel .indicators {
	background: rgba(0, 0, 0, 0.5);
}

.carousel-text {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translateX(-50%);
	z-index: 1000;
	color: black;
	text-shadow: 0 0 6px white;
}

@media (max-width: 600px) {
	.carousel .carousel-item > img {
		width: 100%;
		margin-left: 0;
		margin-top: 0;
		max-height: 115px;
	}

	.carousel {
		height: 300px;
	}

	.carousel-slider .carousel-item > img {
		width: 100%;
		margin-left: 0;
		margin-top: 0;
		max-height: 100%;
	}
}

.d-none {
	display: none !important;
}
.d-block {
	display: block !important;
}
.opacity-0 {
	opacity: 0;
	transition: 0.3s;
}

.opacity-1 {
	opacity: 1;
	transition: 0.3s;
}

.list1 {
	/*overflow: hidden;*/
	margin: 0 0 15px 0;
	max-height: 150px;
	transition: all 0.5s;
}

.list2 {
	/*overflow: hidden;*/
	margin: 0 0 15px 0 !important;
	max-height: 1500px !important;
	transition: all 0.5s !important;
}

.list1-close {
	/*overflow: hidden;*/
	margin: 0 0 0 -250px !important;
	max-height: 0 !important;
	width: 0 !important;
	transition: all 0.5s !important;
}

.btn {
	border-radius: 5px;
	box-shadow: none;
}

.select-wrapper input.select-dropdown {
	margin-bottom: 23px;
}

.bgRed input.select-dropdown,
.bgGreen input.select-dropdown,
.bgGray input.select-dropdown {
	color: white !important;
	height: 2rem !important;
	border-bottom: none;
	text-align: center;
	margin-bottom: 0;
}

/*.select-wrapper + label {*/
/*top: -24px !important;*/
/*}*/

.bgRed {
	background-color: #cc3402 !important;
}

.bgGreen {
	background-color: #75b000 !important;
}

.bgGray {
	background-color: #a0a0a0 !important;
}

.bRadius-7 {
	border-radius: 7px !important;
	width: 150px;
}

.bRadius-7 .select-wrapper + label {
	top: -29px !important;
}

.active-hover {
	background-color: #f0f3f9 !important;
	border-radius: 5px;
}

.my:hover {
	background-color: #f0f3f9 !important;
	border-radius: 5px;
}

.fa {
	min-width: 24px !important;
}

tr:hover i.fa.fa-pencil-square-o {
	opacity: 1 !important;
}

tr:hover i.fa.fa-trash-o {
	opacity: 1 !important;
}

tr:hover i.fa.fa-eye {
	opacity: 1 !important;
}

tr:hover i.fa.fa-upload {
	opacity: 1 !important;
}

.bb-none {
	border-bottom: none !important;
}

.afterHeader {
	margin-top: 65px;
}

.mt-noHeader {
	margin-top: 32px !important;
}

.mt-noHeader h5 {
	margin-top: 0 !important;
	padding-top: 0 !important;
}

.blue.lighten-1 {
	background-color: #6f7bd7 !important;
}

textarea.materialize-textarea {
	height: 2.5rem;
	min-height: 2.5rem !important;
}

.sb-bolder {
	font-weight: 600;
	color: #4e525a;
}

.td-actions {
	min-width: 72px;
	max-width: 72px;
	width: 72px;
}

.td-date {
	min-width: 72px;
	max-width: 72px;
	width: 72px;
}

.td-cOn {
	min-width: 110px;
}
.td-cBy {
	min-width: 147px;
}
.td-titleMAW {
	max-width: 150px;
	width: 150px;
	word-wrap: break-word;
}
.td-titleMAW-250 {
	max-width: 250px;
	width: 250px;
	word-wrap: break-word;
}
.td-titleMAW-350 {
	max-width: 350px;
	width: 350px;
	word-wrap: break-word;
}

.mb0 .select-wrapper input.select-dropdown {
	margin-bottom: 0;
	border-bottom: none;
}

ul li.noHover:hover {
	background-color: #fff !important;
}

.plate-wrapper {
    display: grid;
    grid-gap: 15px;
    overflow: hidden;
    /*   grid-template-columns: repeat(auto-fill, 200px); */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /*   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr) 150px); */
    grid-auto-rows: 300px;
    grid-auto-flow: dense;
}

.plate-item:nth-of-type(3n) {
    -ms-grid-column-span: 2;
    grid-column: span 2;
}

.plate-item:nth-of-type(5n) {
    -ms-grid-row-span: 2;
    grid-row: span 2;
}

.plate-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border: 1px solid red;
    transition: 0.3s;
}
.plate-item:hover {
    border: 2px solid yellow;
    border-radius: 10px;
    transition: 0.3s;
}
.plate-item h4 {
    /*word-break: break-all;*/
}

@media (max-width: 1100px) {
    .plate-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .plate-item {
        padding: 5px;
        border: 1px solid red;
        width: 100%;
    }
}
.multicolor-bar {
    /*margin: 20px 20%;*/
    margin-right: 20px;
}

.multicolor-bar .values .value {
    float: left;
    text-align: center;
}

.multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
}

.multicolor-bar .bars .bar {
    float: left;
    height: 25px;
}

.multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.multicolor-bar .legends {
    text-align: center;
}


.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
    font-size: 25px;
    vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}


body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	position: relative;
	overflow-x: hidden;
}

ul.marked {
	margin-left: 20px;
}

ul.marked li {
	list-style: circle !important;
}

p {
	padding: 0;
	margin: 0;
}

.justified p {
	text-indent: 20px;
	text-align: justify;
}

p.redB b {
	color: #ee0000;
}

a {
	outline: none;
}
.modal {
	width: 50% !important; /* Регулируйте ширину по вашему усмотрению */
	/* height: 300px !important; */
	/* max-height: 100% !important; */
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

/* Основной стиль hoverable */
.hoverable {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px; /* Ширина и высота по вашему усмотрению */
	height: 20px;
	border-radius: 5px; /* Закругленные углы, если необходимо */
	transition: background-color 0.3s, color 0.3s; /* Плавный переход цвета */
	-webkit-user-select: none;
	        user-select: none; /* Отключает выделение текста */
}

/* Стиль иконки */
.icon {
	color: #000; /* Изначальный цвет иконки */
	opacity: 0.7; /* Прозрачность иконки */
	transition: color 0.3s; /* Плавный переход цвета */
}

/* Изменение цвета иконки при наведении */
.hoverable:hover .icon {
	color: #000; /* Цвет иконки при наведении (например, красный) */
	opacity: 1; /* Установите прозрачность на 100% */
}

.varhover {
	cursor: pointer;
	padding: 3px 10px;
	background-color: #fff;
	border-radius: 0 5px 5px 0;
	height: 100%;
	border-left: 1px solid #e0e0e0;
	transition: background-color 0.3s;
}

.varhover:hover {
	background-color: #e0e0e0;
}

/* .circle-background,
.circle-progress {
  fill: '#fff';
} */

.circle-dashes {
	stroke: #fff;
}

.circle-progress {
	stroke: #f99123;
	z-index: 999;
}

.circle-full {
	stroke: #32cd32;
}

table.highlight th {
	text-align: center;
}


table.highlight td {
	text-align: center;

flex-direction: column;
	justify-content: center;
}

