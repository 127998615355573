.plate-wrapper {
    display: -ms-grid;
    display: grid;
    grid-gap: 15px;
    overflow: hidden;
    /*   grid-template-columns: repeat(auto-fill, 200px); */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /*   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr) 150px); */
    grid-auto-rows: 300px;
    grid-auto-flow: dense;
}

.plate-item:nth-of-type(3n) {
    -ms-grid-column-span: 2;
    grid-column: span 2;
}

.plate-item:nth-of-type(5n) {
    -ms-grid-row-span: 2;
    grid-row: span 2;
}

.plate-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border: 1px solid red;
    transition: 0.3s;
}
.plate-item:hover {
    border: 2px solid yellow;
    border-radius: 10px;
    transition: 0.3s;
}
.plate-item h4 {
    /*word-break: break-all;*/
}

@media (max-width: 1100px) {
    .plate-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .plate-item {
        padding: 5px;
        border: 1px solid red;
        width: 100%;
    }
}