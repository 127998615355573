@import '~materialize-css/dist/css/materialize.min.css';
@import './css/materialize-extender.css';
@import './css/plate.css';
@import './css/colorBar.css';

body {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	position: relative;
	overflow-x: hidden;
}

ul.marked {
	margin-left: 20px;
}

ul.marked li {
	list-style: circle !important;
}

p {
	padding: 0;
	margin: 0;
}

.justified p {
	text-indent: 20px;
	text-align: justify;
}

p.redB b {
	color: #ee0000;
}

a {
	outline: none;
}
.modal {
	width: 50% !important; /* Регулируйте ширину по вашему усмотрению */
	/* height: 300px !important; */
	/* max-height: 100% !important; */
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

/* Основной стиль hoverable */
.hoverable {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px; /* Ширина и высота по вашему усмотрению */
	height: 20px;
	border-radius: 5px; /* Закругленные углы, если необходимо */
	transition: background-color 0.3s, color 0.3s; /* Плавный переход цвета */
	user-select: none; /* Отключает выделение текста */
}

/* Стиль иконки */
.icon {
	color: #000; /* Изначальный цвет иконки */
	opacity: 0.7; /* Прозрачность иконки */
	transition: color 0.3s; /* Плавный переход цвета */
}

/* Изменение цвета иконки при наведении */
.hoverable:hover .icon {
	color: #000; /* Цвет иконки при наведении (например, красный) */
	opacity: 1; /* Установите прозрачность на 100% */
}

.varhover {
	cursor: pointer;
	padding: 3px 10px;
	background-color: #fff;
	border-radius: 0 5px 5px 0;
	height: 100%;
	border-left: 1px solid #e0e0e0;
	transition: background-color 0.3s;
}

.varhover:hover {
	background-color: #e0e0e0;
}

/* .circle-background,
.circle-progress {
  fill: '#fff';
} */

.circle-dashes {
	stroke: #fff;
}

.circle-progress {
	stroke: #f99123;
	z-index: 999;
}

.circle-full {
	stroke: #32cd32;
}

table.highlight th {
	text-align: center;
}


table.highlight td {
	text-align: center;

flex-direction: column;
	justify-content: center;
}
